// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CopyrightSvgrepoComsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CopyrightSvgrepoComsvgIcon(
  props: CopyrightSvgrepoComsvgIconProps
) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"currentColor"}
      viewBox={"0 0 32 32"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M16 32a16 16 0 0016-16A16 16 0 004.69 4.69a16 16 0 000 22.62A15.86 15.86 0 0016 32zM6.1 6.1a14 14 0 0119.8 0 14 14 0 11-19.8 0z"
        }
      ></path>

      <path
        d={
          "M16 25.18a9.15 9.15 0 006.49-2.69 1 1 0 00-1.41-1.41 7.18 7.18 0 110-10.16 1 1 0 001.41-1.41 9.17 9.17 0 00-13 0A9.18 9.18 0 0016 25.18z"
        }
      ></path>
    </svg>
  );
}

export default CopyrightSvgrepoComsvgIcon;
/* prettier-ignore-end */
